import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import moment from "moment"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"
import TopicsList from "/src/components/topics-list"
import BannerList from "/src/components/banner-list"

import topPic01 from "/src/images/top-pic-01.png"
import topPic02 from "/src/images/top-pic-02.png"
import topPic03 from "/src/images/top-pic-03.png"
import topPic04 from "/src/images/top-pic-04.png"
import topicon01 from "/src/images/top-icon-01.png"
import topicon02 from "/src/images/top-icon-02.png"
import topicon03 from "/src/images/top-icon-03.png"
import topicon04 from "/src/images/top-icon-04.png"

const Page = ({data}) => {

  const bredcrumb=''
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  
  let noticestyle = 'none'
  data.notice.edges.forEach(({node}) => (
      
      ( ('' !== (lang === 'ja' ? node.frontmatter.title : node.frontmatter.titleen))
        && (
            node.frontmatter.period === null 
            || node.frontmatter.period === ""
            || moment(node.frontmatter.period).format(`YYYY-MM-DD`) >= moment().format(`YYYY-MM-DD`))) 
      ? noticestyle = '' 
        : ''
    
  ))
  const notice = noticestyle === 'none' 
                  ? <></> 
                  : 
                  <>
                    <div className="topics-list-wrap notice">
                      <div className="content-box-wrap">
                        <div className="content-box">
                          <h2>{t("重要なお知らせ")}</h2>
                          <ul className="topics-list">
                            {data.notice.edges.map(({node}) => ( 
                                <>
                                  {(node.frontmatter.period === null 
                                    || node.frontmatter.period === ""
                                    || moment(node.frontmatter.period).format(`YYYY-MM-DD`) >= moment().format(`YYYY-MM-DD`)) 
                                    ? <TopicsList lang={lang} node={node} /> : ''}
                                </>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>

  let topicsstyle = 'none'
   data.topics.edges.forEach(({node}) => (
    ('' !== (lang === 'ja' ? node.frontmatter.title : node.frontmatter.titleen) )
    ? topicsstyle = ''
    : ''
  ))
  const topics = topicsstyle === 'none' 
                  ? <></> 
                  : 
                  <>
                    <div className="topics-list-wrap topicsbox ">
                      <div className="content-box-wrap">
                        <div className="content-box">
                          <h2>{t("お知らせ")}</h2>
                          <ul className="topics-list">
                            {data.topics.edges.map(({node}) => ( 
                                <>
                                  <TopicsList lang={lang} node={node} /> 
                                </>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title="home" lang={lang} />
      <article id="main" className="top">
        <h1 className="screen-reader-text">{t("理化学研究所 筑波キャンパス")}</h1>
        
        <div className="banner-wrap">
          {data.banner.edges.map(({node}) => ( 
                    <>
                      {(node.frontmatter.period === null 
                        || node.frontmatter.period === ""
                        || moment(node.frontmatter.period).format(`YYYY-MM-DD`) >= moment().format(`YYYY-MM-DD`)) 
                        ? <BannerList lang={lang} node={node} /> : ''}
                    </>
                ))}
        </div>
        
        {notice}
        
        {topics}

        <div className="top-box-wrap-01">
          <img className="top-pic-01" src={ topPic01 } alt="" />
          <div className="top-box-01">
            <h2><Link to="/campus/">{t("筑波キャンパスについて")}</Link></h2>
            
            <ul className="child-pages-icon">
              <li>
                <Link to="/campus/history/">
                  <img src={ topicon01 } alt=""  width="64"/>
                  <span className="title">{t("沿革")}</span>
                </Link>
              </li>
              <li>
                <Link to="/campus/organization/">
                  <img src={ topicon02 } alt=""  width="64"/>
                  <span className="title">{t("組織図")}</span>
                </Link>
              </li>
              <li>
                <Link to="/campus/facilities/">
                  <img src={ topicon03 } alt=""  width="53"/>
                  <span className="title">{t("所内施設")}</span>
                </Link>
              </li>
              <li>
                <Link to="/campus/disclosure/">
                  <img src={ topicon04 } alt=""  width="49"/>
                  <span className="title">{t("公開情報")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>


        <div className="top-box-wrap">
          <div className="top-img-box">
            <img className="top-img" src={ topPic02 } alt="" />
          </div>
          
          <div className="top-box top-img-02">
            <h2><Link to="/centers/">{t("センター紹介")}</Link></h2>
            <ul>
              <li>
                <AnchorLink href="/centers/#anchor01">{t("バイオリソース研究センター")}</AnchorLink>
              </li>
              
            </ul>
          </div>
        </div>


        <div className="top-box-wrap rev-box">
          <div className="top-box top-img-03">
          <h2><Link to="/experience/">{t("研究成果の普及")}</Link></h2>
            <ul>
              <li>
                <AnchorLink href="/experience/#anchor01">{t("見学案内")}</AnchorLink>
              </li>
              <li>
                <AnchorLink href="/experience/#anchor02">{t("一般公開")}</AnchorLink>
              </li>
              <li>
                <AnchorLink href="/experience/#anchor03">{t("ちびっこ博士")}</AnchorLink>
              </li>
              <li>
                <AnchorLink href="/experience/#anchor04">{t("おとなのためのサイエンス講座")}</AnchorLink>
              </li>
              
            </ul>
            
          </div>
          <div className="top-img-box">
            <img className="top-img" src={ topPic03 } alt="" />
          </div>
          
        </div>


        <div className="top-box-wrap">
          <div className="top-img-box">
            <img className="top-img" src={ topPic04 } alt="" />
          </div>
          <div className="top-box top-img-04">
            <h2><Link to="/partnerships/">{t("地域での活動")}</Link></h2>
            <ul>
              <li>
                <AnchorLink href="/partnerships/#anchor01">{t("連携大学院")}</AnchorLink>
              </li>
              <li>
                <AnchorLink href="/partnerships/#anchor02">{t("つくばライフサイエンス推進協議会")}</AnchorLink>
              </li>
              <li>
                <AnchorLink href="/partnerships/#anchor03">{t("筑波研究学園都市交流協議会")}</AnchorLink>
              </li>
              <li>
                <AnchorLink href="/partnerships/#anchor04">{t("筑波会議")}</AnchorLink>
              </li>
            </ul>
          </div>
        </div>



      </article>
    </Layout>
  )
}
export default Page



export const query = graphql`
  query($language: String!, $draft: [Boolean]!, $startdate: Date!) {

    banner: allMarkdownRemark(
      sort: {fields: [fields___slug], order: DESC}
      filter: {
        frontmatter: {post: {eq: "banner"}}
        fields: {draft: { in: $draft }}
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            titleen
            internal
            attachments {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            attachmentsen {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            period
            post
          }
          fields {
            slug
          }
        }
      }
    }

    notice: allMarkdownRemark(
      sort: {fields: [fields___slug], order: DESC}
      filter: {
        frontmatter: {post: {eq: "notice"}}
        fields: {draft: { in: $draft }}
      }
    ) {
      edges {
        node {
          frontmatter {
            year(formatString: "YYYY")
            date(formatString: "YYYY年M月D日")
            dateen: date(formatString: "MMM. D, YYYY")
            title
            titleen
            external
            externalen
            externalext
            internal
            anchor
            nolink
            titlelang
            attachments {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            attachmentsen {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            period
            post
          }
          fields {
            slug
          }
        }
      }
    }

    topics: allMarkdownRemark( 
      sort: {fields: [fields___slug], order: DESC}
      filter: {
        frontmatter: {
          post: {eq: null}
          date: {gte: $startdate}
        }
        fields: {draft: { in: $draft }}
      }
      ) {
      edges { 
        node { 
          frontmatter { 
            year( formatString: "YYYY") 
            postdate:date
            date(formatString: "YYYY年M月D日")
            dateen:date(formatString: "MMM. D, YYYY")
            title 
            titleen
            external
            externalen
            externalext
            internal
            anchor
            nolink
            titlelang
            attachments {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            attachmentsen {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            post
          }
          fields {
            slug 
          }
        }
      }
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`
