import * as React from "react"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import {Link as OrginLink} from "gatsby"

function BannerList(props) {
  
  const {t} = useTranslation();
  const title = props.lang==='ja' ? props.node.frontmatter.title : props.node.frontmatter.titleen
  const internal = props.node.frontmatter?.internal||''
  const publicURL = props.lang==='ja' ? props.node.frontmatter.attachments?.publicURL || '' : props.node.frontmatter.attachmentsen?.publicURL || ''
  const base = props.lang==='ja' ? props.node.frontmatter.attachments?.base || '' : props.node.frontmatter.attachmentsen?.base || ''
  
  
  let link = ''

  if(internal!==''){
    link = <Link to={internal} >
            <img src={publicURL} alt={title} />
          </Link>
  }else{
    link = <img src={publicURL} alt={title} />
  }
  

  if(title && publicURL){
    return (
      <>
        {link}
      </>
    )
  }else{
    return (
      <></>
    )
  }
}

export default BannerList
